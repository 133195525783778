import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Row, Col } from "react-bootstrap"
import CoverImage from "../components/cover-image"
import Helmet from "react-helmet"

class CustomChildren extends Component {
  render() {
    const children = []
    children.push(
      <Col lg="3" className={"text-center mb-2"} key={"category-col-kids"}>
        <a className="d-block" href="/categories/kids-birthday-cakes">
          <CoverImage image={this.props.kidsBirthdayImage} />
          <div className="category-name-container">
            <strong className="category-name">Kids Birthday Cakes</strong>
          </div>
        </a>
      </Col>
    )

    const edges = this.props.children
    edges.forEach((x, index) => {
      if (x.node.frontmatter.images && x.node.frontmatter.images.length > 2) {
        children.push(
          <Col
            lg="3"
            className={"text-center mb-2"}
            key={"category-col-" + index}
          >
            <a className="d-block" href={x.node.fields.slug}>
              <CoverImage image={x.node.frontmatter.cover_photo} />
              <div className="category-name-container">
                <strong className="category-name">
                  {x.node.frontmatter.title}
                </strong>
              </div>
            </a>
          </Col>
        )
      }
    })
    return <Row className={"align-items-center"}>{children}</Row>
  }
}

const CustomCakesPageTemplate = ({ data }) => (
  <Layout>
    <Helmet>
      <title>
        Custom Cakes - Order Custom Cakes Online - Same Day Delivery
      </title>
      <meta
        name="description"
        content="Custom Cakes: Made-to-order custom cakes that make a special occasion a little more meaningful. Order custom cakes online. Home delivery also available."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Custom Cakes - Order Custom Cakes Online - Same Day Delivery"
      />
      <meta
        property="og:description"
        content="Custom Cakes: Made-to-order custom cakes that make a special occasion a little more meaningful. Order custom cakes online. Home delivery also available."
      />
      <meta
        property="og:image"
        content={
          "https://www.deliciaecakes.com/images/homepage/gifting-square.jpg"
        }
      />
      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:title"
        content="Custom Cakes - Order Custom Cakes Online - Same Day Delivery"
      />
      <meta
        property="twitter:description"
        content="Custom Cakes: Made-to-order custom cakes that make a special occasion a little more meaningful. Order custom cakes online. Home delivery also available."
      />
      <meta
        property="twitter:image"
        content={
          "https://www.deliciaecakes.com/images/homepage/gifting-square.jpg"
        }
      />
    </Helmet>
    <div className={"light-bg gifting-page"}>
      <div className="container-fluid py-5 pb-0">
        <div className="row">
          <div className="col-md-12">
            <h1 className={"text-center mb-5"}>Custom Cakes</h1>
            <p>
              Deliciae specialises in carefully designed custom cakes across
              across Mumbai & Bengaluru.
            </p>
            <p>
              These include a wide array of 3D cakes designed &amp; customised
              for every occasion. Choose from a wide range of designs for
              engagement cakes, kids birthday cakes, anniversary cakes, baby
              shower cakes, and theme cakes apt for birthdays and corporate
              events.
            </p>
            <p>
              Our core focus is on Design &amp; Taste and our aim is to always
              give you the best cake. Distinct style &amp; unique techniques are
              used to handcraft a beautiful cake.
            </p>
            <p>
              Online ordering, payment and doorstep delivery make for a seamless
              experience.
            </p>
            <CustomChildren
              children={data.children.edges}
              kidsBirthdayImage={data.kidsBirthdayImage}
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

CustomCakesPageTemplate.propTypes = {
  children: PropTypes.object,
  kidsBirthdayImage: PropTypes.object,
}

const CustomCakesPage = result => {
  return <CustomCakesPageTemplate data={result.data} />
}

CustomCakesPage.propTypes = {
  children: PropTypes.object,
  kidsBirthdayImage: PropTypes.object,
}

export default CustomCakesPage

export const query = graphql`
  query CustomCakesPageTemplate {
    children: allMarkdownRemark(
      sort: { fields: frontmatter___position }
      filter: {
        frontmatter: {
          templateKey: { eq: "category-page" }
          parent: { eq: "Other Occasions" }
        }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            templateKey
            parent
            cover_photo {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
            images {
              image {
                id
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    kidsBirthdayImage: file(absolutePath: { regex: "/cover-kids-bday.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 768) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
